body > #root{
  height: 100%;
}

body > #root >.login-grid {
  background-size:cover;
  height: 100%;
  background-color: #fff;
  background-image: radial-gradient(650px at 40% 100%,#ffffff 0,#f2f4f8 90%);
}

.login-column {
  max-width: 420px;
}
